import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Page } from './Page';
import { TeamsAverageGoals } from './TeamsAverageGoals';
import { PageTitle } from './PageTitle';
import { MainMatchesData, getMatchAnalysis } from './MainMatchesData';
import { LastMatchesAndResultPrevision } from './LastMatchesAndResultPrevision';
import { GoalsPrediction } from './GoalsPrediction';
import { GoalPerMinuteAndHead2Head } from './GoalPerMinuteAndHead2Head';

export const MatchAnalysisPage = () => {
  const { matchId } = useParams();
  const [matchAnalysis, setMatchAnalysis] = React.useState<MainMatchesData>();

  // Ref with explicit type assignment
  const containerRef = useRef<HTMLDivElement>(null);

  const parsedMatchId = matchId ? parseInt(matchId, 10) : undefined;

  useEffect(() => {
    doGetMatchAnalysis();
  }, []);

  const doGetMatchAnalysis = async () => {
    if (parsedMatchId !== undefined) {
      const retMatches = await getMatchAnalysis(parsedMatchId);
      setMatchAnalysis(retMatches);

      // Scroll to the center after data is loaded and DOM is updated
      setTimeout(() => {
        if (containerRef.current) {
          const containerWidth = containerRef.current.scrollWidth;
          const viewportWidth = window.innerWidth;
          const scrollPosition = (containerWidth - viewportWidth) / 2;

          window.scrollTo({
            top: 0, // Adjust if you need vertical scrolling
            left: scrollPosition,
            behavior: 'smooth',
          });
        }
      }, 1);
    }
  };

  return (
    <div ref={containerRef} className="AnalysisContainer">
      <Page>
        {matchAnalysis && (
          <div>
            <LastMatchesAndResultPrevision selectedItemData={matchAnalysis} />
          </div>
        )}
        {matchAnalysis && (
          <div>
            <TeamsAverageGoals selectedItemData={matchAnalysis} />
          </div>
        )}
        {matchAnalysis && (
          <div>
            <GoalsPrediction selectedItemData={matchAnalysis} />
          </div>
        )}
        {matchAnalysis && (
          <div>
            <GoalPerMinuteAndHead2Head selectedItemData={matchAnalysis} />
          </div>
        )}
      </Page>
    </div>
  );
};
