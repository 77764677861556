import React, { useEffect, useRef, useState } from 'react';
import { MatchList } from './MatchList';
import {
  getDrawPredictions,
  getExactResultPredictions,
  getGoalGoalPredictions,
  getNoGoalPredictions,
  getOver2Point5Predictions,
  getUnder3Point5Predictions,
  MainMatchesPrevisionData,
} from './MainMatchesData';
import { PageTitle } from './PageTitle';
import { Page } from './Page';

export const HomePage = () => {
  const [matches, setMatches] = useState<MainMatchesPrevisionData[]>([]);
  const [matchesLoading, setMatchesLoading] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const [selectedDate, setSelectedDate] = useState('');

  useEffect(() => {
    // Imposta la data odierna solo una volta al caricamento iniziale
    const today = new Date().toISOString().split('T')[0];
    setSelectedDate(today);
    doGetGoalGoalPredictions(today); // Caricamento iniziale con la data odierna
  }, []);

  useEffect(() => {
    if (matchesLoading && containerRef.current) {
      // Scroll to the center of the container after content is loaded
      setTimeout(() => {
        if (containerRef.current) {
          const containerWidth = containerRef.current.scrollWidth;
          const viewportWidth = window.innerWidth;
          const scrollPosition = (containerWidth - viewportWidth) / 2;

          window.scrollTo({
            top: 0,
            left: scrollPosition,
            behavior: 'smooth',
          });
        }
      }, 1);
    }
  }, [matchesLoading]);

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = event.target.value;
    setSelectedDate(newDate); // Imposta la data selezionata
    doGetGoalGoalPredictions(newDate); // Ricarica le partite con la nuova data
  };

  const doGetGoalGoalPredictions = async (date: string) => {
    const retMatches = await getGoalGoalPredictions(date); // Passa la data come parametro
    setMatches(retMatches);
    setMatchesLoading(false);
  };

  const doGetNoGoalPredictions = async (date: string) => {
    const retMatches = await getNoGoalPredictions(date); // Passa la data come parametro
    setMatches(retMatches);
    setMatchesLoading(false);
  };

  const doGetOver2Point5Predictions = async (date: string) => {
    const retMatches = await getOver2Point5Predictions(date); // Passa la data come parametro
    setMatches(retMatches);
    setMatchesLoading(false);
  };

  const doGetUnder3Point5Predictions = async (date: string) => {
    const retMatches = await getUnder3Point5Predictions(date); // Passa la data come parametro
    setMatches(retMatches);
    setMatchesLoading(false);
  };

  const doGetDrawPredictions = async (date: string) => {
    const retMatches = await getDrawPredictions(date); // Passa la data come parametro
    setMatches(retMatches);
    setMatchesLoading(false);
  };

  const doGetExactResultPredictions = async (date: string) => {
    const retMatches = await getExactResultPredictions(date); // Passa la data come parametro
    setMatches(retMatches);
    setMatchesLoading(false);
  };

  const handleButtonClick = async (type: string) => {
    setMatchesLoading(true);
    switch (type) {
      case 'GoalGoal':
        await doGetGoalGoalPredictions(selectedDate); // Usa la data selezionata
        break;
      case 'NoGoal':
        await doGetNoGoalPredictions(selectedDate); // Usa la data selezionata
        break;
      case 'Over2.5':
        await doGetOver2Point5Predictions(selectedDate); // Usa la data selezionata
        break;
      case 'Under3.5':
        await doGetUnder3Point5Predictions(selectedDate); // Usa la data selezionata
        break;
      case 'Draw':
        await doGetDrawPredictions(selectedDate); // Usa la data selezionata
        break;
      case 'ExactResult':
        await doGetExactResultPredictions(selectedDate); // Usa la data selezionata
        break;
      default:
        break;
    }
  };

  return (
    <Page>
      <div className="predictionsMenu">
        <PageTitle>Matches Prediction</PageTitle>
        <input
          type="date"
          id="matchDate"
          name="matchDate"
          value={selectedDate}
          onChange={handleDateChange} // Usa la funzione per gestire il cambiamento della data
        />
        <button onClick={() => handleButtonClick('GoalGoal')}>Goal</button>
        {/* <button onClick={() => handleButtonClick('NoGoal')}>No Goal</button> */}
        <button onClick={() => handleButtonClick('Over2.5')}>Ov. 2.5</button>
        <button onClick={() => handleButtonClick('Under3.5')}>Un. 3.5</button>
        <button onClick={() => handleButtonClick('Draw')}>X</button>
        <button onClick={() => handleButtonClick('ExactResult')}>Result</button>
      </div>

      <div>
        {matchesLoading ? <div>Loading...</div> : <MatchList data={matches} />}
      </div>
    </Page>
  );
};
