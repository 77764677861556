import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

export const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo">
            FA
          </Link>
          <div className="menu-icon" onClick={toggleNavbar}>
            <i className={isOpen ? 'fas fa-times' : 'fas fa-bars'}></i>
          </div>
          <ul className={isOpen ? 'navbar-menu active' : 'navbar-menu'}>
            <li className="navbar-item">
              <Link to="/" className="navbar-link" onClick={toggleNavbar}>
                Home
              </Link>
            </li>
            {/* <li className="navbar-item">
              <Link to="/about" className="navbar-link" onClick={toggleNavbar}>
                Leagues
              </Link>
            </li> */}
          </ul>
        </div>
      </nav>
      {isOpen && <div className="backdrop" onClick={toggleNavbar}></div>}
    </>
  );
};
