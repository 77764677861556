import React, { useMemo } from 'react';
import { MainMatchesData } from './MainMatchesData';
import { Header } from './Header';

interface Props {
  selectedItemData: MainMatchesData;
}

export const TeamsAverageGoals = ({ selectedItemData }: Props) => {
  if (!selectedItemData) {
    return <div>No data available</div>;
  }
  return (
    <div className="TeamsAverageGoals">
      <div className="last5container" style={{ display: 'flex' }}>
        {/* Tabella 1 */}
        <table
          style={{ marginRight: '20px', width: '200px', fontSize: '12px' }}
        >
          <thead>
            <tr>
              <th colSpan={4}>Average Goals Half Time</th>
            </tr>
            <tr>
              <th colSpan={2}>Home Team</th>
            </tr>
            <tr>
              <th>Scored</th>
              <th>Conceded</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>-</td>
              <td>-</td>
            </tr>
            <tr>
              <td>{selectedItemData.AverageGoals.HT_HomeScoredAtHome}</td>
              <td>{selectedItemData.AverageGoals.HT_HomeConcededAtHome}</td>
            </tr>
            <tr>
              <td>{selectedItemData.AverageGoals.HT_HomeScoredLast5AtHome}</td>
              <td>
                {selectedItemData.AverageGoals.HT_HomeConcededLast5AtHome}
              </td>
            </tr>
          </tbody>
        </table>

        {/* Tabella 2 */}
        <table
          style={{ marginRight: '20px', width: '500px', fontSize: '12px' }}
        >
          <thead>
            <tr>
              <th colSpan={5}>Average Goals</th>
            </tr>
            <tr>
              <th colSpan={2}>Home</th>
              <th></th>
              <th colSpan={2}>Away</th>
            </tr>
            <tr>
              <th>Scored</th>
              <th>Conceded</th>
              <th></th>
              <th>Scored</th>
              <th>Conceded</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{selectedItemData.AverageGoals.HomeTeamAllScored}</td>
              <td>{selectedItemData.AverageGoals.HomeTeamAllConceded}</td>
              <td>All Matches</td>
              <td>{selectedItemData.AverageGoals.AwayTeamAllScored}</td>
              <td>{selectedItemData.AverageGoals.AwayTeamAllConceded}</td>
            </tr>
            <tr>
              <td>{selectedItemData.AverageGoals.HomeTeamOnlyHomeScored}</td>
              <td>{selectedItemData.AverageGoals.HomeTeamOnlyHomeConceded}</td>
              <td>Only Home - Only Away</td>
              <td>{selectedItemData.AverageGoals.AwayTeamOnlyAwayScored}</td>
              <td>{selectedItemData.AverageGoals.AwayTeamOnlyAwayConceded}</td>
            </tr>
            <tr>
              <td>{selectedItemData.AverageGoals.HomeTeamLast5HomeScored}</td>
              <td>{selectedItemData.AverageGoals.HomeTeamLast5HomeConceded}</td>
              <td>Last 5 Home - Last 5 Away</td>
              <td>{selectedItemData.AverageGoals.AwayTeamLast5AwayScored}</td>
              <td>{selectedItemData.AverageGoals.AwayTeamLast5AwayConceded}</td>
            </tr>
          </tbody>
        </table>

        {/* Tabella 3 */}
        <table style={{ width: '200px', fontSize: '12px' }}>
          <thead>
            <tr>
              <th colSpan={4}>Average Goals Half Time</th>
            </tr>
            <tr>
              <th colSpan={2}>Away Team</th>
            </tr>
            <tr>
              <th>Scored</th>
              <th>Conceded</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>-</td>
              <td>-</td>
            </tr>
            <tr>
              <td>{selectedItemData.AverageGoals.HT_AwayScoredAway}</td>
              <td>{selectedItemData.AverageGoals.HT_AwayConcededAway}</td>
            </tr>
            <tr>
              <td>{selectedItemData.AverageGoals.HT_AwayScoredLast5Away}</td>
              <td>{selectedItemData.AverageGoals.HT_AwayConcededLast5Away}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
